var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-page-header", {
    staticClass: "domain-header",
    attrs: {
      content: this.$route.query.zone
    },
    on: {
      back: _vm.goBack
    }
  }), _c("el-tabs", {
    model: {
      value: _vm.activeName,
      callback: function callback($$v) {
        _vm.activeName = $$v;
      },
      expression: "activeName"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: _vm.$t("menus.记录列表"),
      name: "tab-recorder"
    }
  }, [_c("domain")], 1), _c("el-tab-pane", {
    attrs: {
      label: _vm.$t("menus.自定义线路"),
      name: "tab-customizeline"
    }
  }, [_c("customizeLines")], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };
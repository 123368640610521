import "core-js/modules/es.array.push.js";
import domain from '@/view/zone/domain/domain.vue';
import customizeLines from '@/view/customizeLines/customizeLines.vue';
export default {
  components: {
    domain: domain,
    customizeLines: customizeLines
  },
  name: "zoneDetail",
  data: function data() {
    return {
      activeName: 'tab-recorder'
    };
  },
  methods: {
    goBack: function goBack() {
      this.$router.push({
        name: "zone"
      });
    }
  }
};